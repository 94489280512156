
$( document ).ready(function() {


	setDefaultDueDate();

	$('.generate_api_url code').on('click', function(e) {
		$(this).select();
	})

	$('form#generateQRpay').submit(function(event) {

		event.preventDefault();
		let params = $(this).serialize();
		$.ajax({
			type: 'GET',
			headers: { "cache-control": "no-cache" },
			url: api_ajax_url,
			async: true,
			cache: false,
			dataType: 'json',
			data: params,
			success: function (jsonData) {
				console.log(jsonData);
				if(!jsonData.hasError)
				{
					$('#qrPay').attr('src', jsonData.response);
					$('.generate_api_url code').text(api_ajax_url + "?" + params);
				}
			}
		});
	})
})

function setDefaultDueDate()
{
	var date = new Date();

	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();

	if (month < 10) month = "0" + month;
	if (day < 10) day = "0" + day;

	var today = year + "-" + month + "-" + day;       
	$("#due_date").attr("value", today)
	console.log(today);
}

function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}